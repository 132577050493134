import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <Nav className="flex-column">
      <Nav.Link as={Link} to="/admin/dashboard">Dashboard</Nav.Link>
      <Nav.Link as={Link} to="/admin/categories">Categories</Nav.Link>
    </Nav>
  );
};

export default Sidebar;
