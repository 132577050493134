import React from "react";
import './Myorder.scss'; 
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Powerbank from '../../assets/Images/Powerbank.png';


const Sidebar1 = () => {
  return (
    <div className="container siderbar-container">
      <aside className="sidebar1">
        <div className="profile-section">
          <span className="hello-text" style={{ paddingLeft: '15px' }}>Filters</span>
        </div>
        <hr className="profile-sidebar"></hr>
        <nav className="nav-section">
          <ul>
            <li>
              <span style={{ paddingLeft: '15px', color: '#333', fontWeight: 'bold' }}>ORDER STATUS</span>
            </li>
            <ul className="Myorder-menu">
              <label>
                <input type="checkbox" name="status" value="On the way" /> On the way
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="Delivered" /> Delivered
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="Cancelled" /> Cancelled
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="Returned" /> Returned
              </label>
              <br></br>
            </ul>
            <hr className="profile-sidebar"></hr>
            <li>
              <span style={{ paddingLeft: '15px', pointerEvents: 'none', color: '#333', fontWeight: 'bold' }}>ORDER TIME</span>
            </li>
            <ul className="Myorder-menu">
              <label>
                <input type="checkbox" name="status" value="Last 30 days" />Last 30 days
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="2023" /> 2023
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="2022" /> 2022
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="2021" /> 2021
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="2020" /> 2020
              </label>
              <br></br>
              <label>
                <input type="checkbox" name="status" value="Older" /> Older
              </label>
              <br></br>
            </ul>
          </ul>
        </nav>
      </aside>
      </div>
  );
};



const Myorder = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/delivered'); // Redirect to the "/delivered" page
  };

  return (
    <MainLayout>
      <div className="my-orders-container">
      <Sidebar1 />
      
        <main className="main-content1">
          <div className="Searchbar">
          <input
            type="text"
            className="search"
            placeholder="Search your order here"
          />
          </div>
          <br></br>
          <section>
          <form className="form-layout1" onClick={handleClick}>
            <div className="table1">
            <Row>
              <Col>
              <img
                src={Powerbank}
                style={{ width: '70px', height: '70px' }} 
              />
              </Col>
              <Col className="custom-padding">
                <h6>Mi 20000 mAh 18 W Power Bank</h6>
                <p className="custom-paragraph">Color: Black</p>
              </Col>
              <Col>3 of 2</Col>
              <Col>4 of 2</Col>
            </Row>
            </div>
            <br></br>
           {/* No button here, the form itself triggers the redirect */}
          </form>
          </section>
        </main>
      </div>
    </MainLayout>
  );
};

export default Myorder;
