import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import './Success.scss';  // Custom CSS for animations
import CartLayout from '../../layouts/CartLayout';

const Success = () => {
  return (
    <CartLayout>
    <Container className="bg-white order-placed-container text-center" style={{height:"80vh"}}>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <div className="icon-container">
            <BsCheckCircle className="success-icon" />
          </div>
          <h1 className="animate-fade-in">Order Placed Successfully!</h1>
          <p className="animate-fade-in">
            Thank you for your purchase. Your order has been placed and will be processed soon.
          </p>
          <Button variant="primary" className="animate-slide-up" href="/">
            Continue Shopping
          </Button>
        </Col>
      </Row>
    </Container>
    </CartLayout>
  );
};

export default Success;
