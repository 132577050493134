import React from 'react';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import { Route, Routes } from 'react-router-dom';
import OffersList from './components/OfferList/OfferList';
import Categories from './components/Categories/Categories';
import Profile from './components/Profile/Profile';
import Products from './components/Products/Products';
import './App.scss'
import Checkout from './Pages/Checkout/Checkout';
import Success from './Pages/Success/Success';
import Myorder from './components/Myorder/Myorder';
import Delivered from './components/Myorder/Delivered';
import Login from './Pages/Login/Login';
import SignUp from './Pages/SignUp/SignUp';
import Unauthorized from './Pages/Unauthorized/Unauthorized';
import RoleBasedRoute from './components/RoleBasedRoute/RoleBasedRoute';
import AdminPage from './Pages/Admin/AdminPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Dashboard/>}></Route>
      <Route path='/product/:id' element={<Products />} />
      <Route path='/offers-list/:dealType' element={<OffersList />} />
      <Route path='/categories/:category' element={<Categories/>} />
      <Route path='/Profile' element={<Profile/>} />
      <Route path='/Checkout' element={<Checkout/>} />
      <Route path='/success' element={<Success/>} />
      <Route path='/Myorder' element={<Myorder />} />
      <Route path='/delivered' element={<Delivered/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/signup' element={<SignUp/>} />
      <Route path='/unauthorized' element={<Unauthorized/>} />
      <Route path="/admin/*" element={<AdminPage />} />
      {/* <RoleBasedRoute path="/admin/*" component={AdminPage} requiredRoles={['admin']} /> */}
    </Routes>
  );
}

export default App;
