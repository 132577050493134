import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Form,
  Breadcrumb
} from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import "./Categories.css";
import products from "../../assets/Data/BestDeals.json";
import gender from "../../assets/Data/Gender.json";
import clothCategories from "../../assets/Data/ClothCategories.json";
import { FaAngleDown, FaAngleLeft } from "react-icons/fa";
import ChecboxFilter from "../Filters/CheckboxFilter/ChecboxFilter";
import SliderFilter from "../Filters/SliderFilter.js/SliderFilter";

export default function Categories() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const availabilityData = ["Exclude Out Of Stock"];
  const OfferData = ["Buy More, Save More", "No Cost EMI", "Special Price"];
  const RatingData = ["4★ & above", "3★ & above"];
  const GSTInvoiceAvailableData = ["GST Invoice Available"];
  const DiscountData = [
    "50% or more",
    "40% or more",
    "30% or more",
    "70% or more",
    "60% or more"
  ];
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleShowMoreOrLess = () => {
    setShowAll(!showAll);
  };

  // Display only first 5 categories initially
  const displayedCategories = showAll
    ? clothCategories
    : clothCategories.slice(0, 5);

  return (
    <MainLayout>
      <Container fluid className="categories-main">
        <Row>
          {/* Sidebar with filters */}
          <Col md={2}>
            <Card className="categories-sidebar">
              <div className="d-flex justify-content-between filter-heading">
                <div className="title">Filters</div>
                <div className="clearAll">Clear All</div>
              </div>
              <div className="filter-heading">
                <div className="secondarytitle">Categories</div>
                <div
                  className="category-heading"
                  onClick={handleToggle}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <span style={{ marginRight: "8px" }}>
                    {isExpanded ? <FaAngleDown /> : <FaAngleLeft />}
                  </span>
                  <div className="category-title">Clothing and Accessories</div>
                </div>

                {isExpanded && (
                  <div className="subcategory-list">
                    {displayedCategories.map((cat, index) => (
                      <a key={index} className="categories-link" href="#">
                        {cat}
                      </a>
                    ))}
                    {clothCategories.length > 5 && (
                      <a
                        className="categories-link categories-link-showmore"
                        href="#"
                        onClick={handleShowMoreOrLess}
                      >
                        {showAll
                          ? "Show less"
                          : `Show ${clothCategories.length} more`}
                      </a>
                    )}
                  </div>
                )}
              </div>
              <ChecboxFilter title="Gender" data={gender} />
              <SliderFilter title="Price" data={gender} />
              <ChecboxFilter title="Availability" data={availabilityData} />
              <ChecboxFilter title="Offers" data={OfferData} />
              <ChecboxFilter title="Customer Ratings" data={RatingData} />
              <ChecboxFilter
                title="GST Invoice Available"
                data={GSTInvoiceAvailableData}
              />
              <ChecboxFilter title="Discount" data={GSTInvoiceAvailableData} />
            </Card>
          </Col>

          {/* Center card where products are listed */}
          <Col md={10}>
            <Card className="categories-products">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/categories">Categories</Breadcrumb.Item>
                <Breadcrumb.Item active>Current Page</Breadcrumb.Item>
              </Breadcrumb>
              <h2>Product Listings</h2>
              <Row>
                {products.map((data) => {
                  return (
                    <Col md={3}>
                      <Card className="product-card mt-3">
                        <Card.Img
                          className="categories-product-img"
                          variant="top"
                          src={data.image}
                        />
                        <Card.Body>
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.price}</Card.Text>
                          <Button variant="primary">Add to Cart</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}

                {/* Add more product cards as needed */}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
