import './Footer.css';
import payment from '../../assets/Images/payment.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
export default function Footer() {
    return (
        <footer className="site-footer">
            <div className="footer-content">
                <div className="footer-column">
                    <nav>
                    <p>ABOUT</p>
                        <ul className="footer-links">
                            <li><a href="/Contact Us">Contact Us</a></li>
                            <li><a href="/About Us">About Us</a></li>
                            <li><a href="/Careers">Careers</a></li>
                            <li><a href="/UniTrade Horizons Stories">UniTrade Horizons Stories</a></li>
                            <li><a href="/press">Press</a></li>
                            <li><a href="/Corporate Information">Corporate Information</a></li>
                        </ul>
                    </nav>
                </div>


                <div className="footer-column">
                    <p>GROUP COMPANIES</p>
                    <nav>
                        <ul className="footer-links">
                            <li><a href="/Myntra">Myntra</a></li>
                            <li><a href="/Cleartrip">Cleartrip</a></li>
                            <li><a href="/shopsy">Shopsy</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="footer-column">
                    <p>HELP</p>
                    <nav>
                        <ul className="footer-links">
                            <li><a href="/Payments">Payments</a></li>
                            <li><a href="/Shipping">Shipping</a></li>
                            <li><a href="/Cancellation &">Cancellation &</a></li>
                            <li><a href="/Returns">Returns</a></li>
                            <li><a href="/FAQ">FAQ</a></li>
                            <li><a href="/Report Infringement">Report Infringement</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="footer-column">
                    <p>CONSUMER POLICY</p>
                    <nav>
                        <ul className="footer-links">
                            <li><a href="/Cancellation &">Cancellation &</a></li>
                            <li><a href="/Returns">Returns</a></li>
                            <li><a href="/Terms Of Use">Terms Of Use</a></li>
                            <li><a href="/Security">Security</a></li>
                            <li><a href="/Privacy">Privacy</a></li>
                            <li><a href="/Sitemap">Sitemap</a></li>
                            <li><a href="/Grievance Redressal">Grievance Redressal</a></li>
                            <li><a href="/ERP Compliance">ERP Compliance</a></li>
                        </ul>
                    </nav>
                </div>
                
                <div className="line-container">
                <hr className="perpendicular-line" />
                </div>

                <div className="footer-column1">
                    <nav>
                    <p>Mail Us:</p>
                        <ul className="footer-links">
                            <li>Filpkart Internet Private Limited,</li>
                            <li>Buildings Alyssa, Begonia &</li>
                            <li>Clove Embassy Tech Village,</li>
                            <li>Outer ring Road, Devarabessanahalli Village,</li>
                            <li>Bengaluru, 560103,</li>
                            <li>Karnataka, India,</li>
                        </ul>
                    </nav>
                </div>

                <div className="footer-column1">
                    <nav>
                    <p>Registered Office Address:</p>
                        <ul className="footer-links">
                            <li>Unitrade Horizons Private Limited,</li>
                            <li>Buildings Alyssa, Begonia &</li>
                            <li>Clove Embassy Tech Village,</li>
                            <li>Outer ring Road, Devarabessanahalli Village,</li>
                            <li>Bengaluru, 560103,</li>
                            <li>Karnataka, India,</li>
                            <li>CIN: U51109KA2012PTC066107</li>
                            <li>Telephone : 044-45614700/044-67415800</li>
                        </ul>
                    </nav>
                </div>

            </div>

            <hr className="footer-divider" />
            <div className="footer-content">
                <div className="footer-column-second-row">
                   <nav>
                    <li><a href="Help Center"><i className="fas fa-question-circle"></i> Help Center</a></li>
                   </nav>
                </div>

                <div className="footer-column-second-row">
                   <nav>
                    <li><a href="Become a Seller"><i className="fas fa-store"></i> Become a Seller</a></li>
                   </nav>
                </div>

                <div className="footer-column-second-row">
                   <nav>
                    <li><a href="Gift Cards"><i className="fas fa-gift"></i> Gift Cards</a></li>
                   </nav>
                </div>

                <div className="footer-column-second-row">
                   <nav>
                    <li><a href="Advertise"><i className="fas fa-bullhorn"></i> Advertise</a></li>
                   </nav>
                </div>
                <div className='footer-column-second-row'>
                    <li>@ 2024 Filpkart.com:</li>
                </div>
                <div>
                    <img src={payment}>
                    </img>
                </div>
            </div>
                
        </footer>
    );
}
