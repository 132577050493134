import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
  Stack
} from "react-bootstrap";
import CartLayout from "../../layouts/CartLayout";
import "./Checkout.scss";
import {
  FaBell,
  FaGooglePay,
  FaInfoCircle,
  FaStar,
  FaTruck
} from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Checkout() {
  const [showLogin, setShowLogin] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [payByUPI, setPayByUPI] = useState(true);
  const navigate = useNavigate();
  const payNow=()=>{
    navigate("/success")
  }
  const onLogout=()=>{
    navigate("/login")
  }
  return (
    <CartLayout>
      <Container className="checkout mt-2">
        <Row>
          <Col xs={8}>
            <div className="bg-white shadow-box me-4">
              {!showLogin ? (
                <Stack direction="horizontal" c>
                  <div className="p-2 ms-2">
                    <span className="number-card">1</span>
                  </div>
                  <div className="p-2 me-auto">
                    <div className="text-gray fw-bold">LOGIN</div>
                    <div>
                      <span className="fw-bold">Jeyaprakash</span>
                      <span className="text-14 ms-2">+91 9840348428</span>
                    </div>
                  </div>
                  {/* <div className="vr" /> */}
                  <div className="p-2">
                    <Button
                      className="button-light"
                      onClick={() => {
                        setShowLogin(true);
                      }}
                    >
                      CHANGE
                    </Button>
                  </div>
                </Stack>
              ) : (
                <>
                  <Stack direction="horizontal" className="bg-blue p-3" gap={1}>
                    <div className="ms-2">
                      <span className="number-card-white">1</span>
                    </div>
                    <div className="me-auto">
                      <div className="text-white fw-bold">LOGIN</div>
                    </div>
                  </Stack>
                  <div className="login-details text-14">
                    <Row>
                      <Col xs={6}>
                        <div className="p-2">
                          <span className="text-gray">Name</span>
                          <span className="fw-bold ms-2">
                            Jeyaprakash George
                          </span>
                        </div>
                        <div className="p-2">
                          <span className="text-gray">Phone Number</span>
                          <span className="fw-bold ms-2">+91 9840348428</span>
                        </div>
                        <div className="p-2 text-blue fw-bold" onClick={onLogout}>
                          Logout & Sign in to another account
                        </div>
                        <div className="p-2">
                          <Button
                            className="buynow-btn"
                            onClick={() => {
                              setShowLogin(false);
                            }}
                          >
                            Continue Checkout
                          </Button>
                        </div>
                      </Col>
                      <Col xs={6} className="ps-3">
                        <div className="text-14 text-gray fw-bold">
                          Advantages of Secure Login
                        </div>
                        <div className="mt-2">
                          <FaTruck className="me-2" />
                          Easily Track Orders, Hassle free Returns
                        </div>
                        <div className="mt-2">
                          <FaBell className="me-2" />
                          Get Relevant Alerts and Recommendation
                        </div>
                        <div className="mt-2">
                          <FaStar className="me-2" />
                          Wishlist, Reviews, Ratings and more.
                        </div>
                      </Col>
                      <div className="mt-5 text-gray">
                        Please note that upon clicking "Logout" you will lose
                        all items in cart and will be redirected to Flipkart
                        home page.
                      </div>
                    </Row>
                  </div>
                </>
              )}
            </div>
            {!showDeliveryAddress ? (
              <Stack
                direction="horizontal"
                className="bg-white mt-2 shadow-box me-4 pt-2 pb-2"
                onClick={() => {
                  setShowDeliveryAddress(true);
                }}
              >
                <div className="p-2 ms-2">
                  <span className="number-card">2</span>
                </div>
                <div className="p-2 me-auto">
                  <div className="text-gray fw-bold uppercase">
                    Delivery Address
                  </div>
                </div>
              </Stack>
            ) : (
              <>
                <Stack
                  direction="horizontal"
                  className="bg-blue  mt-2 shadow-box me-4 pt-2 pb-2"
                  gap={1}
                  onClick={() => {
                    setShowDeliveryAddress(false);
                  }}
                >
                  <div className="p-2 ms-2">
                    <span className="number-card-white">2</span>
                  </div>
                  <div className="me-auto">
                    <div className="text-white fw-bold">Delivery Address</div>
                  </div>
                </Stack>
                <div className="delivery-details text-14 bg-white me-4">
                  <Row>
                    <Col xs={1}>
                      <Form.Check // prettier-ignore
                        type="radio"
                      />
                    </Col>
                    <Col xs={9} style={{ marginLeft: "-18px" }}>
                      <div className="mb-2">
                        <span className="fw-bold">Jeyaprakash</span>
                        <span className="ms-2 bg-gray p-1 ">Home</span>
                        <span className="fw-bold ms-2">9840348428 </span>
                      </div>
                      <div className="mb-2">
                        <span>
                          K32 Jagannath Orchid Apartment, Thaiyur, Kelambakkam
                          post, Chennai 603103, Chennai, Kelambakkam, Tamil Nadu
                          - <span class="fw-bold">603103</span>
                        </span>
                      </div>
                      <Row className="mb-2">
                        <Col xs={8}>
                          <Button
                            className="buynow-btn"
                            onClick={() => {
                              setShowLogin(false);
                            }}
                          >
                            Deliver Here
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={2} className="ps-3" />
                  </Row>
                </div>
              </>
            )}

            {!showOrderSummary ? (
              <Stack
                direction="horizontal"
                className="bg-white mt-2 shadow-box me-4 pt-2 pb-2"
                onClick={() => {
                  setShowOrderSummary(true);
                }}
              >
                <div className="p-2 ms-2">
                  <span className="number-card">3</span>
                </div>
                <div className="p-2 me-auto">
                  <div className="text-gray fw-bold uppercase">
                    Order Summary
                  </div>
                </div>
              </Stack>
            ) : (
              <>
                <Stack
                  direction="horizontal"
                  className="bg-blue  mt-2 shadow-box me-4 pt-2 pb-2"
                  gap={1}
                  onClick={() => {
                    setShowOrderSummary(false);
                  }}
                >
                  <div className="p-2 ms-2">
                    <span className="number-card-white">2</span>
                  </div>
                  <div className="me-auto">
                    <div className="text-white fw-bold">Order Summary</div>
                  </div>
                </Stack>
                <div className="delivery-details text-14 bg-white me-4">
                  <Row>
                    <Col xs={2}>
                      <div>
                        <Image
                          src="https://rukminim2.flixcart.com/image/100/100/xif0q/sock/u/k/u/free-10-ankle-trendymix-n-10-pair-hicode-original-imagz3sg5thwmf7p.jpeg?q=90"
                          thumbnail
                        />
                      </div>
                    </Col>
                    <Col xs={6} className="ms-2">
                      <div>
                        <div className="h6 text-black">
                          HICODE Men Ankle Length
                        </div>
                        <div className="text-14 text-gray fw-bold">
                          Size: Free,Pack of 10
                        </div>
                        <div className="text-14 text-gray mt-2">
                          Seller: parishitradingcompany
                        </div>
                        <div>
                          <span className="cut">₹998</span>
                          <span className="h5 fw-bold ms-2">₹269</span>
                          <span className="text-14 text-green ms-2">
                            73% Off
                          </span>
                          <span className="text-14 text-green ms-2 fw-bold">
                            1 offer applied
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={4} className="ps-3" />
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <Row className="mt-2">
                        <Col xs={3} className="p-2 mt-0">
                          <Button className="round-btn text-black">-</Button>
                        </Col>
                        <Col xs={6} className="pe-0">
                          <Form.Control
                            className="addtocart-text"
                            type="text"
                            placeholder="0"
                          />
                        </Col>
                        <Col xs={3} className="ps-1 mt-1">
                          <Button className="round-btn text-black">+</Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={3}>
                      <Button className="bg-white border-0 mt-2 remove-btn fw-bold">
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {!showPaymentOption ? (
              <Stack
                direction="horizontal"
                className="bg-white mt-2 shadow-box me-4 pt-2 pb-2"
                onClick={() => {
                  setShowPaymentOption(true);
                }}
              >
                <div className="p-2 ms-2">
                  <span className="number-card">4</span>
                </div>
                <div className="p-2 me-auto">
                  <div className="text-gray fw-bold uppercase">
                    Payment Option
                  </div>
                </div>
              </Stack>
            ) : (
              <>
                <Stack
                  direction="horizontal"
                  className="bg-blue  mt-2 shadow-box me-4 pt-2 pb-2"
                  gap={1}
                  onClick={() => {
                    setShowPaymentOption(false);
                  }}
                >
                  <div className="p-2 ms-2">
                    <span className="number-card-white">4</span>
                  </div>
                  <div className="me-auto">
                    <div className="text-white fw-bold uppercase">
                      Payment Option
                    </div>
                  </div>
                </Stack>

                <div className="delivery-details text-14 bg-white me-4 bottom-border">
                  <Row>
                    <Col xs={1}>
                      <Form.Check // prettier-ignore
                        value={payByUPI}
                        type="radio"
                        name="paymentType"
                      />
                    </Col>
                    <Col xs={11} style={{ marginLeft: "-18px" }}>
                      <Stack direction="horizontal">
                        <FaGooglePay className="rounded" />
                        <span className="ms-2 fw-bold">
                          UPI{" "}
                          <div className="text-gray">Pay by any UPI App</div>
                        </span>
                      </Stack>
                      <div className="ms-4 mt-2">
                        <div className="fw-bold">Choose an Option</div>
                        <div className="mt-2">
                          <Form.Check
                            type="radio"
                            id="phonepe"
                            name="upiOption" // Grouped by name
                            label="Phone Pe"
                          />
                        </div>
                        <div className="mt-2">
                          <Form.Check
                            type="radio"
                            id="upiid"
                            name="upiOption" // Grouped by name
                            label="Upi ID"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className="delivery-details text-14 bg-white me-4 "
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <Row>
                    <Col xs={1}>
                      <Form.Check // prettier-ignore
                        value={payByUPI}
                        type="radio"
                        name="paymentType"
                      />
                    </Col>
                    <Col xs={6} style={{ marginLeft: "-18px" }}>
                      <div className="fw-bold ms-2">
                        {" "}
                        Credit / Debit / ATM Card
                      </div>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Enter Card Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Card Number"
                        />
                      </FloatingLabel>
                      <Row>
                        <Col xs={6}>
                          <FloatingLabel
                            controlId="floatingvalidthru"
                            label="Valid Thru"
                          >
                            <Form.Control type="text" placeholder="MM/YY" />
                          </FloatingLabel>
                        </Col>
                        <Col xs={6}>
                          <FloatingLabel controlId="floatingcvv" label="CVV">
                            <Form.Control
                              type="password"
                              placeholder="Password"
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Button className="buynow-btn mt-2">Pay ₹55,355</Button>
                    </Col>
                  </Row>
                </div>
                <div
                  className="delivery-details text-14 bg-white me-4 "
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <Row>
                    <Col xs={1}>
                      <Form.Check // prettier-ignore
                        value={payByUPI}
                        type="radio"
                        name="paymentType"
                      />
                    </Col>
                    <Col xs={11} style={{ marginLeft: "-18px" }}>
                      <div className="fw-bold ms-2"> Net Banking</div>
                      <div className="fw-bold mt-3 ms-2">Popular Banks</div>
                      <Row className="mt-4">
                        {[
                          "HDFC Bank",
                          "ICICI Bank",
                          "State Bank Of India",
                          "Axis Bank",
                          "Kotak Mahindra Bank"
                        ].map((data) => (
                          <Col xs={4} key={`default-${data}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type="radio"
                              name="netbanking"
                              label={data}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Select aria-label="Default select example">
                            <option>Other Banks</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                          <Button onClick={payNow} className="buy-gray-btn mt-2">
                            Pay ₹55,355
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div
                  className="delivery-details text-14 bg-white me-4 "
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <Row>
                    <Col xs={1}>
                      <Form.Check // prettier-ignore
                        value={payByUPI}
                        type="radio"
                        name="paymentType"
                      />
                    </Col>
                    <Col xs={11} style={{ marginLeft: "-18px" }}>
                      <div className="fw-bold ms-2"> Cash on Delivery</div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Col>
          <Col className="shadow-box bg-white" xs={3}>
            <Stack gap={3}>
              <div className="p-2">
                <span className="text-gray-heading">Price Details</span>
              </div>
              <div
                className="p-2"
                style={{ borderBottom: "2px solid #f0f0f0" }}
              >
                <Row style={{ margin: "20px 0", fontWeight: "500" }}>
                  <Col xs={9}>Price (1 item)</Col>
                  <Col xs={3}>₹259</Col>
                </Row>
                <Row style={{ margin: "20px 0", fontWeight: "500" }}>
                  <Col xs={8}>Delivery Charges</Col>
                  <Col xs={4} className="cut text-14">
                    ₹40 <span className="text-green fw-bold">FREE</span>
                  </Col>
                </Row>
                <Row style={{ margin: "20px 0", fontWeight: "500" }}>
                  <Col xs={9}>
                    Platform Fee <FaInfoCircle className="ms-2" />
                  </Col>
                  <Col xs={3} className="cut text-14">
                    ₹3
                  </Col>
                </Row>
                <hr className="dotted-hr" />
                <Row style={{ margin: "20px 0", fontWeight: "bold" }}>
                  <Col xs={9}>
                    Total Payable <FaInfoCircle className="ms-2" />
                  </Col>
                  <Col xs={3} className="">
                    ₹262
                  </Col>
                </Row>
              </div>
              <div className="p-2">
                <div
                  className="text-green"
                  style={{ margin: "20px 0", fontWeight: "bold" }}
                >
                  Your Total Savings on this order is ₹737
                </div>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </CartLayout>
  );
}
