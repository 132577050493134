import { useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Image, Row, Stack, Alert } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import "./SignUp.scss";
import logo from "../../assets/Images/logo.svg";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate();
  
  // State for form fields
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  // State for validation messages and error handling
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // SignUp function to handle API call
  const onSignUp = async () => {
    setError("");

    // Basic validation
    if (!fullName || !email || !password || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Password confirmation
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Make the API call to create a new account
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation {
              createUser(fullName: "${fullName}", email: "${email}", password: "${password}") {
                id
                fullName
                email
              }
            }
          `,
        }),
      });

      const result = await response.json();
      setLoading(false);

      // Check for errors in the response
      if (result.errors) {
        setError(result.errors[0].message);
      } else {
        // On success, navigate to the login page
        // Store token in cookies (if your API returns a token, you can store it here)
        document.cookie = `authToken=${result.data.token}; path=/`;

        // Redirect to the login page
        navigate("/login");
      }
    } catch (err) {
      setLoading(false);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
      <div className="loginpage">
        <Row>
          <Col sm={12}>
            <Stack className="bg-white logindiv text-center">
              <h4 className="login-header">Create Account</h4>
              <div className="login-logo">
                <Image src={logo} />
              </div>

              {error && <Alert variant="danger">{error}</Alert>}

              <Form className="login-form">
                <FloatingLabel
                  controlId="floatingname"
                  label="Full Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingConfirmPassword" label="Confirm Password">
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FloatingLabel>

                <Button
                  className="login-btn"
                  size="lg"
                  variant="outline-primary"
                  onClick={onSignUp}
                  disabled={loading}
                >
                  {loading ? "Signing Up..." : "Sign Up"}
                </Button>
              </Form>
              <div className="login-signup-text">
                Already an existing user? <a href="/login">Login In</a>
              </div>
            </Stack>
          </Col>
        </Row>
      </div>
  );
}
