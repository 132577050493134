import React from 'react';
import './ProductCard.css';  // Import the CSS file
import realme from '../../assets/Images/Products/realme.png'
import { useNavigate } from 'react-router-dom';
export default function ProductCard({ id, image, name, price }) {
  let navigate = useNavigate();
  let productOnclick=(id)=>{
    console.log("name",id)
    navigate("/product/"+id)
  }
    return (
      <div className="product-card" onClick={()=>{
        productOnclick(id)
      }}>
        <img src={image} className="product-card-img-top" alt={name} width={186} height={186}/>
        <div className="product-card-body text-center">
          <p className="product-card-title">{name}</p>
          <p className="product-card-text">{price}</p>
        </div>
      </div>
    );
}
