import { useState } from "react";
import { Form } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';
import { FaAngleDown } from "react-icons/fa";
import './SliderFilter.css'

export default function SliderFilter(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [minValue, setMinValue] = useState(250);
  const [maxValue, setMaxValue] = useState(10000);

  const minAmounts = [250, 500, 1000, 5000, 10000];
  const maxAmounts = [1000, 5000, 10000, 15000, 20000];

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMinChange = (value) => {
    const val = parseInt(value);
    setMinValue(val);
    if (val >= maxValue) {
      setMaxValue(maxAmounts.find(amount => amount > val));
    }
  };

  const handleMaxChange = (value) => {
    const val = parseInt(value);
    setMaxValue(val);
    if (val <= minValue) {
      setMinValue(minAmounts.find(amount => amount < val));
    }
  };

  return (
    <div className="filter-heading">
      <div
        className="d-flex justify-content-between category-heading mb-2"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div className="gender-title">{props.title}</div>
        <FaAngleDown />
      </div>
      {isExpanded && (
        <div>
          <RangeSlider
            value={minValue}
            onChange={e => handleMinChange(e.target.value)}
            min={minAmounts[0]}
            max={maxAmounts[maxAmounts.length - 1]}
            step={50}
            tooltip='on'
            tooltipPlacement='top'
          />
          <RangeSlider
            value={maxValue}
            onChange={e => handleMaxChange(e.target.value)}
            min={minAmounts[0]}
            max={maxAmounts[maxAmounts.length - 1]}
            step={50}
            tooltip='on'
            tooltipPlacement='top'
          />
          <div className="d-flex justify-content-between mt-2">
            <div>
              <select
              className="price-dropdown"
              style={{ width: '100px' }} 
                value={minValue}
                onChange={e => handleMinChange(e.target.value)}
              >
                {minAmounts.filter(amount => amount <= maxValue).map((amount, index) => (
                  <option key={index} value={amount}>
                    {amount}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
              className="price-dropdown"
              style={{ width: '100px'}} 
                value={maxValue}
                onChange={e => handleMaxChange(e.target.value)}
              >
                {maxAmounts.filter(amount => amount >= minValue).map((amount, index) => (
                  <option key={index} value={amount}>
                    {amount}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
