import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ProductCarousel.css";
import ProductCard from "../ProductCard/ProductCard";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function BestDeals(props) {
  const [index, setIndex] = useState(0);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(props?.data.length / itemsPerPage);
  const navigate = useNavigate();

  const handleNextClick = (title, data) => {
    navigate(`/offers-list/${title}`, { state: { data } });
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % totalPages);
  };

  const slides = [];
  for (let i = 0; i < totalPages; i++) {
    const visibleProducts = props?.data.slice(
      i * itemsPerPage,
      (i + 1) * itemsPerPage
    );
    slides.push(
      <Carousel.Item key={i}>
        <div className="row">
          {visibleProducts.map((product) => (
            <div
              key={product.id}
              className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
            >
              <ProductCard {...product} />
            </div>
          ))}
        </div>
      </Carousel.Item>
    );
  }

  return (
    <div className="container bg-white mt-2">
      <div className="bestdeals-header px-2 py-2 d-flex justify-content-between ">
        <div className="bestdeals-title">{props.title}</div>
        <FaArrowAltCircleRight
          onClick={() => {
            handleNextClick(props.title, props?.data);
          }}
        />
      </div>
      <div className="carousel-container">
        <button className="carousel-control-prev" onClick={handlePrev}>
          &lt;
        </button>
        <Carousel
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false} // Hide indicators if you don’t need them
          prevLabel=""
          nextLabel=""
        >
          {slides}
        </Carousel>
        <button className="carousel-control-next" onClick={handleNext}>
          &gt;
        </button>
      </div>
    </div>
  );
}

export default BestDeals;
