import React from 'react';

const Addanewaddress = () => {
  return (
    <div>
      
      {/* Form fields go here */}
    </div>
  );
};

export default Addanewaddress;
