import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import logo from "../../assets/Images/logo.svg";
import {
  FaUser,
  FaBox,
  FaHeart,
  FaTag,
  FaBell,
  FaSignOutAlt,
  FaShoppingCart,
  FaRegUserCircle,
  FaEllipsisV,
  FaSearch 
} from "react-icons/fa";
import { useState } from "react";
import './Header.css';

export default function CartHeader() {

  return (
    <Navbar expand="lg" className="bg-white">
      <Container fluid style={{ maxWidth: "1600px",display:"flex", justifyContent:"center" }}>
        <Navbar.Brand className="me-3" href="/">
          <img src={logo} alt="logo" width="160" height="40" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
