import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import CategoryForm from './CategoryForm';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  useEffect(() => {
    // Fetch categories from API or local state
    // setCategories(data);
  }, []);

  const handleAddCategory = () => {
    setEditCategory(null);
    setShowForm(true);
  };

  const handleEditCategory = (category) => {
    setEditCategory(category);
    setShowForm(true);
  };

  const handleDeleteCategory = (id) => {
    // Call API to delete category
    // Update state after deletion
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Button onClick={handleAddCategory} variant="primary">Add Category</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.id}>
                  <td>{category.name}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleEditCategory(category)}>Edit</Button>
                    <Button variant="danger" onClick={() => handleDeleteCategory(category.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {showForm && <CategoryForm category={editCategory} onClose={() => setShowForm(false)} />}
    </Container>
  );
};

export default Categories;
