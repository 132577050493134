import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header/Header";
import CategoriesNav from "./CategoriesNav/CategoriesNav";
import { Container } from "react-bootstrap";
import HomeCarousel from "./HomeCarousel/HomeCarousel";
import ProductCarousel from "./ProductCarousel/ProductCarousel";
import products from '../assets/Data/BestDeals.json';
import topdeals from '../assets/Data/TopDealsInAppliances.json';
import smartGadgets from '../assets/Data/SmartGadgets.json';
import MainLayout from "../layouts/MainLayout";

function Dashboard() {
  const [message, setMessage] = useState("");
  return (
      <MainLayout >
        <Container className="mt-2">
        <HomeCarousel/>
        <ProductCarousel title="Best Deals on SmartPhone" data={products}/>
        <ProductCarousel title="Top Deals on Appliances" data={topdeals}/>
        <ProductCarousel title="Smart Gadgets" data={smartGadgets}/>
        </Container>
      </MainLayout>
  );
}

export default Dashboard;
