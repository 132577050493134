import React, { useState } from 'react';
import Addanewaddress from './Addanewaddress';
import './ManageAddressesForm.scss';

const ManageAddressesForm = () => {
  const [selectedForm, setSelectedForm] = useState("Addanewaddress");

  const handleLinkClick = (form) => {
    setSelectedForm(form);
  };

  return (
    <div className="container1">
      <form className="form-layout1" >
        <h5>Manage Addresses</h5>
        <ul className="manage-menu">
        <li>
            <a
              href="Addanewaddress.js"
              onClick={() => handleLinkClick('Addanewaddress')}
              className="manage-link">
              <i className="fa-solid fa-plus"></i> ADD A NEW ADDRESS
            </a>
          </li>
        </ul>
        <main className="manage-content">
          <section>
            {selectedForm === "Addanewaddress" && <Addanewaddress />}
          </section>
        </main>
      </form>
    </div>
  );
};

export default ManageAddressesForm;
