// src/apolloClient.js
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

// Create an HTTP link for your GraphQL endpoint
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

// Set the context to include the token in the headers
const authLink = setContext((_, { headers }) => {
  // Get the JWT token from cookies
  const token = Cookies.get('token');

  // Return the headers with the authorization token if available
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// Create an Apollo Client instance with the authLink and httpLink
const client = new ApolloClient({
  link: authLink.concat(httpLink), // Combine authLink and httpLink
  cache: new InMemoryCache(),
});

export default client;
