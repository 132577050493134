import { Nav, NavDropdown } from "react-bootstrap";
import categoriesData from "../../assets/Data/Categories.json"; // Assuming the JSON file is in the same directory
import './CategoriesNav.css'; // Import the CSS file for styling

export default function CategoriesNav() {
    const renderDropdown = (subcategories) => {
        return subcategories.map((subcategory, index) => {
            if (subcategory.subcategories) {
                return (
                    <NavDropdown
                        title={subcategory.label}
                        id={`nav-dropdown-${index}`}
                        key={subcategory.label}
                        className="dropdown-hover"
                        drop="end" // Ensure the subcategory dropdown opens to the right
                    >
                        {renderDropdown(subcategory.subcategories)}
                    </NavDropdown>
                );
            } else {
                return (
                    <NavDropdown.Item href={subcategory.href} key={subcategory.label} className="text-black">
                        {subcategory.label}
                    </NavDropdown.Item>
                );
            }
        });
    };

    return (
        <Nav className="text-black bg-white d-flex justify-content-center">
            {categoriesData.map((category, index) => (
                <NavDropdown
                    title={category.label}
                    id={`nav-dropdown-${index}`}
                    key={category.label}
                    className="dropdown-hover text-black"
                >
                    {category.subcategories ? (
                        renderDropdown(category.subcategories)
                    ) : (
                        <NavDropdown.Item href={category.href} className="text-black">
                            {category.label}
                        </NavDropdown.Item>
                    )}
                </NavDropdown>
            ))}
        </Nav>
    );
}
