import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from '../../assets/Images/logo.svg'; // Update with your logo path

const Header = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" width="150" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
