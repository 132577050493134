import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { useLocation, useParams } from "react-router-dom";
import { Card, Container } from "react-bootstrap";
import "./OfferList.css";
import ProductCard from "../ProductCard/ProductCard";
function OfferList() {
  const { dealType } = useParams();
  const location = useLocation();

  // Access the passed data from location.state
  const { data } = location.state || {};
  return (
    <MainLayout>
      <Container className="mt-2">
        {/* <h1>{dealType.replace('-', ' ').toUpperCase()}</h1> */}
        {/* Render specific offers or products based on dealType */}
        <Card className="text-center" body>
          <div className="offerzonetitle">
            {dealType.replace("-", " ").toUpperCase()}
          </div>
          <p className="totalcount">12 Items</p>
        </Card>
        <Card className="container offer-container">
          <div className="row">
            {data?.map((product) => (
              <div
                key={product.id}
                className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4"
              >
                <ProductCard {...product} />
              </div>
            ))}
          </div>
        </Card>
      </Container>
    </MainLayout>
  );
}

export default OfferList;
