import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import logo from "../../assets/Images/logo.svg";
import {
  FaUser,
  FaBox,
  FaHeart,
  FaTag,
  FaBell,
  FaSignOutAlt,
  FaShoppingCart,
  FaRegUserCircle,
  FaEllipsisV,
  FaSearch
} from "react-icons/fa";
import { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { gql, useQuery } from "@apollo/client";

// Define the GraphQL query
const GET_USER_PROFILE = gql`
  query GetUserProfile($id: ID!) {
    userProfile(id: $id) {
      id
      email
      fullName
    }
  }
`;

export default function Header() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMoreOptionsDropdown, setShowMoreOptionsDropdown] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userProfile, setUserProfile] = useState(null); // Add userProfile state

  const handleToggle = () => setExpanded(!expanded);

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  const handleMoreOptionsMouseEnter = () => setShowMoreOptionsDropdown(true);
  const handleMoreOptionsMouseLeave = () => setShowMoreOptionsDropdown(false);

  useEffect(() => {
    const storedUserId = Cookies.get("userId"); // Get userId from cookies
    if (!storedUserId) {
      // navigate("/login"); // Redirect to login if no userId in cookie
    } else {
      setUserId(storedUserId);
    }
  }, [navigate]);

  const onLogout = () => {
    // Clear the token and user details from cookies
    Cookies.remove("token");
    Cookies.remove("userId");
    Cookies.remove("userEmail");
    Cookies.remove("userFullName");

    // Reset userProfile and userId
    setUserId(null);
    setUserProfile(null);

    // Optionally navigate to login page
    // navigate("/login");
  };

  // Execute the query using useQuery hook
  const { loading, error, data } = useQuery(GET_USER_PROFILE, {
    skip: !userId, // Skip query if no userId
    variables: { id: userId },
    onCompleted: (data) => setUserProfile(data.userProfile) // Set userProfile after query completes
  });

  return (
    <Navbar expand="lg" className="bg-white" expanded={expanded}>
      <Container fluid style={{ maxWidth: "1600px" }}>
        <Navbar.Brand className="me-3" href="/">
          <img src={logo} alt="logo" width="160" height="40" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleToggle}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Form className="d-flex w-100 me-4">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search For Products, Brands and More"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Form>
          <Nav className="ms-auto align-items-center">
            {userProfile?.fullName ? (
              <>
                <FaRegUserCircle className="me-1" />
                <NavDropdown
                  className="me-2"
                  title={userProfile?.fullName || "User"}
                  id="basic-nav-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item href="/profile">
                    <FaUser className="me-2" /> My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    <FaBox className="me-2" /> Orders
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.4">
                    <FaHeart className="me-2" /> Whish List (8)
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.5">
                    <FaTag className="me-2" /> Coupons
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.6">
                    <FaBell className="me-2" /> Notifications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.7" onClick={onLogout}>
                    <FaSignOutAlt className="me-2" /> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <Button
                variant="light"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            )}
            <FaShoppingCart className="me-1" />
            <Nav.Link href="#cart">Cart</Nav.Link>
            <Nav.Link href="#become-seller">Seller</Nav.Link>
            <NavDropdown
              className="ms-2 custom-dropdown"
              title={<FaEllipsisV />}
              id="more-options-dropdown"
              align="end"
              show={showMoreOptionsDropdown}
              onMouseEnter={handleMoreOptionsMouseEnter}
              onMouseLeave={handleMoreOptionsMouseLeave}
            >
              <NavDropdown.Item href="#notification-preference">
                Notification Preference
              </NavDropdown.Item>
              <NavDropdown.Item href="#advertise">Advertise</NavDropdown.Item>
              <NavDropdown.Item href="#download-app">
                Download App
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin">
                Admin Dashboard
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
