import React from "react";
import Header from "../components/Header/Header";
import CategoriesNav from "../components/CategoriesNav/CategoriesNav";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer/Footer";


function MainLayout({ children }) {
  return (
    <div className="h-100" style={{ minHeight: "100vh", backgroundColor: "#f1f2f4" }}>
      <Header />
      <CategoriesNav/>
        {children}
        <Footer/>
    </div>
  );
}

export default MainLayout;
