import React from 'react';

function Delivered() {
  return (
    <section>
      <h1>This is the Next Page</h1>
      <p>You have been redirected here after form submission.</p>
    </section>
  );
}

export default Delivered;
