import React, { useState } from 'react';
import './Profile.css';
import Placeholder from '../../assets/Images/Placeholder.png';
import MainLayout from '../../layouts/MainLayout';
import ProfileInformationForm from './ProfileInformationForm';
import ManageAddressesForm from './ManageAddressesForm';

const ProfilePage = () => {
  
  const [selectedForm, setSelectedForm] = useState("profile");

  const handleSidebarClick = (form) => {
    setSelectedForm(form);
  };
  
  return (
    <MainLayout>
    <div className="container profile-container">
      <aside className="sidebar">
        <div className="profile-section">
          <img src={Placeholder} alt="Profile" className="profile-image" />
          <span className="hello-text">Hello, User</span>
        </div>
        <hr className="profile-sidebar"></hr>
        <nav className="nav-section">
          <ul>
          <li>
            <i className="fa-solid fa-message" style={{ color: '#007bff' }}></i>
            <a href="/Myorder" className="my-orders-link">
              MY ORDERS
              <i className="fa-solid fa-angle-right" style={{ paddingLeft: '30px' }}></i>
            </a>
          </li>
            <hr className="profile-sidebar"></hr>
            <li>
              <i className="fa-solid fa-user" style={{ color: '#007bff' }}></i>
              <span style={{ paddingLeft: '15px', pointerEvents: 'none' , color: '#aaaaaa', fontWeight: 'bold'}}>ACCOUNT SETTINGS</span>
            </li>
            <ul className="sub-menu1">
              <li><button onClick={() => handleSidebarClick('profile')} className="sidebar-button">Profile Information</button></li>
              <li><button onClick={() => handleSidebarClick('addresses')} className="sidebar-button">Manage Addresses</button></li>
              <li><a href="/PAN Card Information">PAN Card Information</a></li>
            </ul>
            <hr className="profile-sidebar"></hr>
            <li>
              <i class="fa-solid fa-wallet" style={{color: '#007bff'}}></i>
              <span style={{ paddingLeft: '15px', pointerEvents: 'none', color: '#aaaaaa', fontWeight: 'bold'}}>PAYMENT</span>
            </li>
            <ul className="sub-menu">
              <li><a href="Gift Cards">Gift Cards</a></li>
              <li><a href="Saved UPI">Saved UPI</a></li>
              <li><a href="Saved Cards">Saved Cards</a></li>
            </ul>
            <hr className="profile-sidebar"></hr>
            <li>
              <i class="fa-solid fa-receipt" style={{ color: '#007bff'}}></i> 
              <span style={{ paddingLeft: '15px', pointerEvents: 'none', color: '#aaaaaa', fontWeight: 'bold' }}>MY STUFF</span>
            </li>
            <ul className="sub-menu">
              <li><a href="My Coupons">My Coupons</a></li>
              <li><a href="My Reviews & Ratings">My Reviews & Ratings</a></li>
              <li><a href="All Notifications">All Notifications</a></li>
              <li><a href="My Wishlist">My Wishlist</a></li>
            </ul>
          </ul>
        </nav>
        <hr className="profile-sidebar"></hr>
      </aside>

      
      <main className="main-content">
          <section>
          {selectedForm === "profile" && <ProfileInformationForm />}
          {selectedForm === "addresses" && <ManageAddressesForm />}
          </section>
        </main>
    </div>
    </MainLayout>
  );
};

export default ProfilePage;
