import { useState } from "react";
import { Form } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";

export default function CheckboxFilter(props) {
    const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="filter-heading">
      <div
        className="d-flex justify-content-between category-heading mb-2"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div className="gender-title">{props.title}</div>
        <FaAngleDown />
      </div>
      {isExpanded && (<Form>
        {props.data.map((data) => (
          <div key={`default-checkbox`} className="mb-3">
            <Form.Check // prettier-ignore
              type="checkbox"
              id={data}
              label={data}
            />
          </div>
        ))}
      </Form>)}
    </div>
  );
}
