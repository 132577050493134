import React, { useState } from 'react';

const ProfileInformationForm = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [errors, setErrors] = useState({});
  
    // Form validation function
    const validateForm = () => {
      const newErrors = {};
      const MobileNumber = /^[0-9]{10}$/;
      if (!firstName) newErrors.firstName = "Please fill out this field.";
      if (!lastName) newErrors.lastName = "Please fill out this field.";
      if (!gender) newErrors.gender = "Please fill out this field.";
      if (!email) newErrors.email = "Email address is required";
      if (!MobileNumber || !MobileNumber.test(MobileNumber)) {
        newErrors.MobileNumber = "Mobile number must be a 10-digit number";
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
        alert("Form submitted successfully!");
      }
    };
    const handleMobileNumberChange = (e) => {
      const value = e.target.value;
      if (/^\d{0,10}$/.test(value)) {
        setMobileNumber(value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          MobileNumber: ""
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          MobileNumber: "Mobile number must contain only digits."
        }));
      }
    };

  return (
    <form className="form-layout" onSubmit={handleSubmit}>
              <h5> Personal Information</h5>
              {/* Row with 3 columns */}
              <div className="row">
                {/* First Column: First Name */}
                <div className="col" >
                  <div className="form-group">
                    <label htmlFor="first-name"></label>
                    <input
                      type="text"
                      id="first-name"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errors.firstName && (
                      <span className="error">{errors.firstName}</span>
                    )}
                  </div>
                </div>

                {/* Second Column: Last Name */}
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="last-name"></label>
                    <input
                      type="text"
                      id="last-name"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastName && (
                      <span className="error">{errors.lastName}</span>
                    )}
                  </div>
                </div>

                {/* Third Column: Save Button */}
                <div className="col" >
                  <div className="form-group-save">
                    <button type="submit" className="save-button">Save</button>
                  </div>
                </div>
              </div>

            <div className="row form-gender-row">
              <h9> Your Gender</h9>
               {/* First Column: Male Option */}
              <div className="col1">
                <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                 onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="male">Male</label>
              </div>

              {/* Second Column: Female Option */}
              <div className="col1">
                <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="female">Female</label>
              </div>
           </div>
           {errors.gender && (
           <span className="error">{errors.gender}</span>)}

            <h5> Email Address</h5>
             <div className="row">
                <div className="col" >
                  <div className="form-group">
                    <label htmlFor=" Email Address"></label>
                    <input
                      type="text"
                      id="email-address"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                </div>
                
                <div className="col" >
                  <div className="form-group-save">
                    <button type="submit" className="save-button">Save</button>
                  </div>
                </div>
              </div>

            <h5> Mobile Number</h5>
             <div className="row">
                <div className="col" >
                  <div className="form-group">
                    <label htmlFor=" Mobile Number"></label>
                    <input
                      type="text"
                      id="Mobile Number"
                      placeholder=" Mobile Number"
                      value={MobileNumber}
                      onChange={handleMobileNumberChange}
                    />
                    {errors.MobileNumber && (
                      <span className="error">{errors.MobileNumber}</span>
                    )}
                  </div>
                </div>
                <div className="col" >
                  <div className="form-group-save">
                    <button type="submit" className="save-button">Save</button>
                  </div>
                </div>
              </div>
              <div className='Sub-paragraph'>
              <h5>FAQs</h5>
              <ul>
              <li className="bold-text">What happens when I update my email address (or mobile number)?</li>
              <p> Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number).</p>
              <li className="bold-text">When will my Flipkart account be updated with the new email address (or mobile number)?</li>
              <p> It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes.</p>
              <li className="bold-text">What happens to my existing Flipkart account when I update my email address (or mobile number)?</li>
              <p> Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information and personal details.</p>
              <li className="bold-text">Does my Seller account get affected when I update my email address?</li>
              <p> Flipkart has a 'single sign-on' policy. Any changes will reflect in your Seller account also.</p>
              </ul>
              </div>
              <div>
                <ul>
                <li><a href="Deactivate Account">Deactivate Account</a></li>
                </ul>
                <ul>
                <li className="delete-account" ><a href=" Delete Account"> Delete Account</a></li>
                </ul>
              </div>
              <div className="background-container"> 
              </div>

            </form>
  );
};

export default ProfileInformationForm;
