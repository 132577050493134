import CartHeader from "../components/Header/CartHeader";
import Header from "../components/Header/Header";

export default function CartLayout({ children }) {
  return (
    <div
      className="h-100"
      style={{ minHeight: "100vh", backgroundColor: "#f1f2f4" }}
    >
      <CartHeader />
      {children}
    </div>
  );
}
