import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Stack,
  Alert,
  Spinner
} from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import "./Login.scss";
import logo from "../../assets/Images/logo.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";
import CartLayout from "../../layouts/CartLayout";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    if (!email || !password) {
      setError("Email and password are required.");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Invalid email address.");
      return false;
    }
    return true;
  };

  const onSignIn = async () => {
    setError(""); // Clear any previous error message

    if (!validateForm()) return;

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query: `
                mutation {
                  login(email: "${email}", password: "${password}") {
                    token
                    user {
                      id
                      email
                      fullName
                    }
                  }
                }
              `
        })
      });

      const data = await response.json();

      if (data.errors || !data.data.login) {
        throw new Error(data.errors ? data.errors[0].message : "Login failed.");
      }

      const { token, user } = data.data.login;

    // Store token and user details in cookies
    Cookies.set("token", token); // Store the token
    Cookies.set("userId", user.id); // Store the user ID
    Cookies.set("userEmail", user.email); // Store the user email
    Cookies.set("userFullName", user.fullName); // Store the user's full name

      setLoading(false);
      navigate("/"); // Navigate to the home page
    } catch (err) {
      setLoading(false);
      setError(err.message || "An error occurred. Please try again.");
    }
  };

  return (
      <div className="loginpage">
        <Row>
          <Col sm={12}>
            <Stack className="bg-white logindiv text-center">
              <h4 className="login-header">Login Page</h4>
              <div className="login-logo">
                <Image src={logo} />
              </div>

              {error && <Alert variant="danger">{error}</Alert>}

              <Form className="login-form">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>

                <Button
                  className="login-btn"
                  size="lg"
                  variant="outline-primary"
                  onClick={onSignIn}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form>

              <div className="login-signup-text">
                Don’t have an account? <a href="/signup">Sign Up</a>
              </div>
            </Stack>
          </Col>
        </Row>
      </div>
  );
}
