import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Categories from './Categories';

const AdminPage = () => {
  return (
    <div className="admin-page">
      <Header />
      <Container fluid>
        <Row>
          <Col md={3} className="sidebar">
            <Sidebar />
          </Col>
          <Col md={9} className="content">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/categories" element={<Categories />} />
            </Routes>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AdminPage;
