import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Form,
  Breadcrumb,
  Image,
  Stack
} from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import ProductDetailsThumbnail from "../../assets/Data/ProductDetailsThumbnail.json";
import sizeData from "../../assets/Data/size.json";
import "./Products.css";
import "./Products.scss";
import {
  FaCartArrowDown,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaRegStar,
  FaRupeeSign,
  FaShoppingCart,
  FaStar,
  FaTag
} from "react-icons/fa";
import scale from "../../assets/Images/Icons/scale.svg";
import bankofferdata from "../../assets/Data/BankOffer.json";
import productdetailsinfo from "../../assets/Data/ProductDetailInfo.json";
import { useNavigate } from "react-router-dom";

export default function Categories() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(
    ProductDetailsThumbnail[0].image
  );
  const [showZoom, setShowZoom] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [zoomStyle, setZoomStyle] = useState({ backgroundPosition: "0% 0%" });

  const handleMouseEnter = () => {
    setShowZoom(true);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setZoomStyle({
      backgroundPosition: `${x}% ${y}%`
    });
    setZoomPosition({ x: e.pageX, y: e.pageY });
  };

  const handleMouseLeave = () => {
    setShowZoom(false);
  };
  const buyNowClick=()=>{
    navigate("/Checkout")
  }
  return (
    <MainLayout>
      <Container className="product-main bg-white">
        <div className="product-details-main mb-2">
          <Row>
            <Col xs={5}>
              <Row>
                <Col xs={2}>
                  <Stack gap={1}>
                    {ProductDetailsThumbnail.map((thumbnail) => {
                      return (
                        <div
                          className="product-details-thumbnail"
                          onClick={() => {
                            setSelectedImage(thumbnail.image);
                          }}
                          onMouseEnter={() => {
                            setSelectedImage(thumbnail.image);
                          }}
                        >
                          <Image
                            className="w-100"
                            src={thumbnail?.thumbnail}
                            thumbnail
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </Col>
                <Col>
                  <div className="product-details-image">
                    <Image
                      src={selectedImage}
                      thumbnail
                      onMouseEnter={handleMouseEnter}
                      onMouseMove={handleMouseMove}
                      onMouseLeave={handleMouseLeave}
                    />
                  </div>
                </Col>
              </Row>
              <Stack direction="horizontal" gap={2} className="col-md-12 mx-auto mt-2">
      <Button className="w-50 addtocart-btn" variant="secondary" onClick={buyNowClick}><FaShoppingCart className="me-2" />
Add To Cart</Button>
      <Button className="w-50 buynow-btn" variant="outline-secondary" onClick={buyNowClick}><FaCartArrowDown className="me-2" />
Buy Now</Button>
    </Stack>
            </Col>
            <Col className="product-right-div">
              {showZoom && (
                <Card
                  className="box-shadow"
                  style={{
                    height: "90vh",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundSize: "150%",
                    ...zoomStyle
                  }}
                />
              )}
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  Clothing and Accessories
                </Breadcrumb.Item>
                <Breadcrumb.Item>Top Wear</Breadcrumb.Item>
                <Breadcrumb.Item active>T-Shirts</Breadcrumb.Item>
              </Breadcrumb>
              <div className="product-heading">
                <div className="product-title">VeBNoR</div>
                <span className="product-description-heading">
                  Men Printed Round Neck Polyester Light Green T-Shirt
                </span>
                <div class="product-special">
                  <span>Special price</span>
                </div>
                <div className="d-flex" style={{ alignItems: "center" }}>
                  <div style={{ fontSize: "28px", fontWeight: 500 }}>₹259</div>
                  <div
                    className="ms-2 d-inline-block"
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      color: "#878787",
                      fontWeight: "400"
                    }}
                  >
                    ₹999
                  </div>
                  <div className="product-offer-div me-2">
                    <span>74% off</span>
                  </div>
                  <FaInfoCircle />
                </div>
                <div className="d-flex">
                  <span>
                    <div className="product-rating-badge">
                      3.8
                      <FaStar className="ms-1" />
                    </div>
                  </span>
                  <span className="product-rating-text">
                    1,07,047 ratings and 5,913 reviews
                  </span>
                </div>
                <Row className="pt-4 color-div w-100">
                  <Col xs={3}>Color</Col>
                  <Col>
                    <Row>
                      {ProductDetailsThumbnail.map((thumbnail) => {
                        return (
                          <Col
                            xs={2}
                            onClick={() => {
                              setSelectedImage(thumbnail.image);
                            }}
                            onMouseEnter={() => {
                              setSelectedImage(thumbnail.image);
                            }}
                          >
                            <Image
                              className="w-100"
                              src={thumbnail?.thumbnail}
                              thumbnail
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row className="pt-4 color-div w-100">
                  <Col xs={3}>Size</Col>
                  <Col xs={6}>
                    <Row>
                      {sizeData.map((size) => {
                        return (
                          <Col xs={2} className="p-0">
                            <span className="size-text">{size}</span>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={3}>
                    <span className="size-chart-text">Size Chart</span>
                    <img className="ms-2" src={scale} />
                  </Col>
                </Row>
                <div className="mt-4">
                  <span className="available-offer">Available Offers</span>
                  {bankofferdata &&
                    bankofferdata.map((data) => {
                      return (
                        <div>
                          <FaTag />
                          <span
                            className="ms-2 fw-bold"
                            style={{ fontSize: "14px" }}
                          >
                            {data.type}
                          </span>
                          <span className="ms-2" style={{ fontSize: "14px" }}>
                            {data.description}
                          </span>
                          <span
                            className="ms-2 termsandconditions"
                            style={{ fontSize: "14px" }}
                          >
                            T&C
                          </span>
                        </div>
                      );
                    })}
                </div>
                <Row className="mt-4">
                  <Col xs={6}>
                    <FaMapMarkerAlt className="me-2" />
                    <span className="deliver-text">Deliver To</span>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2 mt-2"
                    >
                      <option>Address</option>
                      <option value="1">K32 Jagannath Orchid Apartment</option>
                    </Form.Select>
                    <span className="fw-bold">Delivery in 2 Days, Monday</span>{" "}
                    | <span className="fw-bold text-green">Free</span>{" "}
                    <span className="text-gray fw-bold cut me-2">₹40</span>
                    <FaInfoCircle />
                    <div className="text-small">if ordered before 4:35 PM</div>
                    <div className="text-blue fw-bold">View Details</div>
                  </Col>
                  <Col xs={6}>
                    <span className="deliver-text">Serivces</span>
                    <span className="ms-4">
                      <FaRupeeSign />
                      <span className="ms-2">Cash on Delivery available</span>
                      <FaInfoCircle className="ms-2" />
                    </span>
                  </Col>
                </Row>
                <hr />
                <Row
                  onClick={() => {
                    setShowProductDetails((prev) => !prev);
                  }}
                >
                  <Col xs={11} className="h4 fw-bold product-details-text">
                    {" "}
                    Product Details
                  </Col>
                  <Col xs={1} className="h5 product-details-text">
                    {showProductDetails ? "-" : "+"}
                  </Col>
                  {showProductDetails && (
                    <Col>
                      {productdetailsinfo &&
                        productdetailsinfo.map((data) => {
                          return (
                            <Row
                              style={{
                                padding: "8px 12px"
                              }}
                            >
                              <Col xs={3} className="fw-bold text-gray">
                                {data.type}
                              </Col>
                              <Col xs={9}>{data.value}</Col>
                            </Row>
                          );
                        })}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} className="h4 fw-bold product-description-title">
                    Product Description
                  </Col>
                  <Col xs={12} className="product-description">
                    <Row>
                      <Col xs={6} md={4}>
                        <Image src="https://rukminim2.flixcart.com/image/200/200/cms-rpd-img/9848135804314f1587ecfb82fa15f5c1_18d7f018906_s-ts12-vebnor-original-imagppbwh9zubpc2.jpeg?q=90" rounded />
                      </Col>
                      <Col>
                      <h4>Round Neck</h4>
                      <div className="text-14">This t-shirt's round neck makes it more suitable for casual occasions. This men's t-shirt having a simple rounded neck provides a loose fit and a comfortable feeling around the neck. No matter what body shape, its design creates a balanced appearance, because of its adaptable neck form, it is simpler to style with various bottom wear and other outfit combinations.

</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="product-description">
                    <Row>
                      <Col>
                      <h4>Extended Sleeves</h4>
                      <div className="text-14">The VeBNoR t-shirt for men includes extended sleeves, making it more weather-appropriate while maintaining its fashionable look. Depending on the occasion, this round-neck t-shirt may be stylishly layered with various coats or vests. This t-shirt is appropriate for professional and informal parties. This full-sleeve t-shirt gives you a refined and gentle appearance.</div>
                      </Col>
                      <Col xs={6} md={4}>
                        <Image src="https://rukminim2.flixcart.com/image/200/200/cms-rpd-img/9848135804314f1587ecfb82fa15f5c1_18d7f018906_s-ts12-vebnor-original-imagppbwh9zubpc2.jpeg?q=90" rounded />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}><h4 className="fw-bold font-14">Ratings and Reviews</h4></Col>
                  <Col xs={5}>
                    <div className="rating-badge-md">
                      3.8
                      <FaStar className="ms-1 mb-1" />
                    </div>
                    <span className="text-gray ms-2 fw-bold">1,07,047 ratings and 5,913 reviews</span>
                    </Col>
                  <Col xs={3}>
                  <Button className="rate-button">Rate Product</Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}
