import React from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './Unauthorized.scss';

const Unauthorized = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
      <Row className="text-center">
        <Col>
          <motion.div
            className="icon-container mb-4"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <FaLock size={100} className="text-danger" />
          </motion.div>
          <h1 className="mb-3">Access Denied</h1>
          <Alert variant="danger">
            <p>You do not have permission to access this page.</p>
          </Alert>
          <Button href="/login" variant="primary" className="mt-3">
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
